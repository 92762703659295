import request from '@/utils/request'

// 获取历史聊天记录
export const historyMessage = (data) => {
  return request({
    method: 'GET',
    url: 'lottery/message/get',
    params: data
  })
}

// 保存聊天记录
export const saveMessage = (data) => {
  return request({
    method: 'GET',
    url: 'lottery/message/send',
    params: data
  })
}
